







import { Component, Prop, Vue } from 'vue-property-decorator';
import { AttributeValueFilter } from '@/models/attribute-value';
import { getModule } from 'vuex-module-decorators';
import FilterModule from '@/store/modules/filter-module';
import store from '@/store/filters';

const filterModule = getModule(FilterModule, store);

@Component
export default class DmqAttributeFilter extends Vue {

  @Prop({
    required: true,
    type: Object,
  })
  public value?: AttributeValueFilter;

  public get apply() {
    return !!this.value && this.value!.apply;
  }

  public set apply(v: boolean) {
    if (this.value) {
      filterModule.checkAttributeValue({
        value: this.value,
        selected: v,
      });
      this.$emit('apply');
    }
  }

}
