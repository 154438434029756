import L, { LatLngTuple, Map } from 'leaflet';

// FIX leaflet's default icon path problems with webpack
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default class DmqMap {

  public readonly map: Map;

  constructor(private el: HTMLElement) {
    const retailerCoords: LatLngTuple = [
      Number(this.el.dataset.latitude || '0'),
      Number(this.el.dataset.longitude || '0'),
    ];
    this.map = L.map(this.el, {
      scrollWheelZoom: false,
    }).setView(retailerCoords, 20);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
    L.marker(retailerCoords).addTo(this.map);
  }
}
