import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import {SearchResults} from '@/models/search';

@Module({ namespaced: true, name: 'search' })
export default class SearchModule extends VuexModule {
  public query: string = '';
  public results: SearchResults | null = null;
  public isSearching: boolean = false;

  @Action
  public setSearching(query: string) {
    this.context.commit('searching', query);
  }

  @Action
  public setSearched(data: SearchResults) {
    this.context.commit('searched', data);
  }

  @Mutation
  private searching(query: string) {
    this.query = query;
    this.isSearching = true;
  }

  @Mutation
  private searched(data: SearchResults) {
    this.results = data;
    this.isSearching = false;
  }
}
