import axios, { AxiosRequestConfig } from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_DMQ_API_URL,
});

api.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers['Content-Type'] = 'application/dmq+json';
  // tslint:disable-next-line:no-string-literal
  config.headers['Accept'] = 'application/dmq+json';
  config.headers['X-Requested-With'] = 'XMLHttpRequest';
  return config;
}, (error: any): any => Promise.reject(error));

export interface ApiError {
  message?: string;
}

export default api;
