export function isSuperset(set: Set<any>, subset: Set<any>) {
  for (const elem of subset) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
}

export function union(setA: Set<any>, setB: Set<any>) {
  const u = new Set(setA);
  for (const elem of setB) {
    u.add(elem);
  }
  return u;
}

export function intersection(setA: Set<any>, setB: Set<any>) {
  const i = new Set();
  for (const elem of setB) {
    if (setA.has(elem)) {
      i.add(elem);
    }
  }
  return i;
}

export function difference(setA: Set<any>, setB: Set<any>) {
  const d = new Set(setA);
  for (const elem of setB) {
    d.delete(elem);
  }
  return d;
}
