
































import { Component, Prop, Vue } from 'vue-property-decorator';
import DmqModal from '@/components/DmqModal.vue';
import DmqMyRegions from '@/components/DmqMyRegions.vue';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import SalesRegionsModule from '@/store/modules/sales-regions-module';
import { SaleRegion } from '@/models/sale-region';

const salesRegionsModule = getModule(SalesRegionsModule, store);

@Component({
  components: {
    DmqModal,
    DmqMyRegions,
  },
})
export default class DmqRegionsModal extends Vue {

  private shown: boolean = false;
  private loading: boolean = false;

  private mounted() {
    this.shown = salesRegionsModule.hasNoSelection;
  }

  private close() {
    this.loading = true;
    salesRegionsModule.selectAllAndSave().then(() => {
      window.location.reload();
    }, () => {
      window.location.reload();
    });
  }

}
