import DmqNavItem from '@/components/DmqNavItem';

export default class DmqNav {

  constructor(private navEl: HTMLElement) {
    navEl.querySelectorAll('.dmq-nav-item').forEach((dni) => {
      const _ = new DmqNavItem(dni as HTMLElement);
    });
  }

}
