import '@/pollyfills/web/closest';
import Vue from 'vue';
import store from './store';

import DmqSearch from '@/components/DmqSearch.vue';
import DmqUserIcon from '@/components/DmqUserIcon.vue';
import DmqCartIcon from '@/components/DmqCartIcon.vue';
import DmqToggler from '@/components/DmqToggler';
import DmqNav from '@/components/DmqNav';
import DmqRegionsModal from '@/components/DmqRegionsModal.vue';
import CartModule from '@/store/modules/cart-module';
import SalesRegionsModule from '@/store/modules/sales-regions-module';
import { getModule } from 'vuex-module-decorators';
import DmqNewsletterSubscribe from '@/components/DmqNewsletterSubscribe.vue';
import DmqNewsletterUnubscribe from '@/components/DmqNewsletterUnsubscribe.vue';
import DmqCheckoutAddresses from "@/components/DmqCheckoutAddresses.vue";

Vue.config.productionTip = false;

new DmqSearch({
  store,
}).$mount('#dmq-search');

new DmqUserIcon({
  store,
}).$mount('#dmq-user-icon');

const cartIconEl = document.getElementById('dmq-cart-icon') as HTMLElement;
const cartData = JSON.parse(cartIconEl.dataset.cart || '');
getModule(CartModule, store).fetched(cartData);
new DmqCartIcon({
  store,
}).$mount(cartIconEl);

const hamburgerEl = document.querySelector('.dmq-main-hamburger') as HTMLElement;
const navEl = document.getElementById(hamburgerEl.dataset.toggle || '') as HTMLElement;

if (hamburgerEl && navEl) {
  const _ = new DmqToggler([hamburgerEl], navEl, 'is-active', 'dmq-nav--fullscreen');
}

document.querySelectorAll('.dmq-nav').forEach((e) => {
  const _ = new DmqNav(e as HTMLElement);
});

const saleRegionsModalEl = document.getElementById('dmq-regions-modal') as HTMLElement;
if (saleRegionsModalEl) {
  getModule(SalesRegionsModule, store).fetched(JSON.parse(saleRegionsModalEl.dataset.saleZones || ''));
  new DmqRegionsModal({
    store,
  }).$mount(saleRegionsModalEl);
}

const newsLetterSubscribeEl = document.getElementById('dmq-newsletter-subscribe') as HTMLElement;
if (newsLetterSubscribeEl) {
  new DmqNewsletterSubscribe({
    propsData: newsLetterSubscribeEl.dataset,
  }).$mount(newsLetterSubscribeEl);
}

const newsLetterUnubscribeEl = document.getElementById('dmq-newsletter-unsubscribe') as HTMLElement;
if (newsLetterUnubscribeEl) {
  new DmqNewsletterUnubscribe({
    propsData: {
      contacts: newsLetterUnubscribeEl.dataset.contacts || [],
      mailingId: parseInt(newsLetterUnubscribeEl.dataset.mailingId || '', 10),
      email: newsLetterUnubscribeEl.dataset.email || ''
    },
  }).$mount(newsLetterUnubscribeEl);
}

/* document.querySelectorAll('.dmq-whishlist-button').forEach((e) => {
  const btEl = e as HTMLElement;
  const productId = btEl.dataset.productId ? parseInt(btEl.dataset.productId, 10) : null;
  if (productId) {
    new DmqWishlistButton({
      store,
      propsData: {
        productId,
      },
    }).$mount(btEl);
  }
}); */
