function isIn(element: HTMLElement, subElement: HTMLElement): boolean {

  let parent: HTMLElement | null = element;
  while (parent) {
    if (parent === subElement) {
      return true;
    }
    parent = parent.parentElement;
  }
  return false;
}

export default class DmqNavItem {

  private readonly navLevel: string | null = null;

  constructor(private navItemEl: HTMLElement) {

    for (const clsName of navItemEl.classList) {
      if (clsName.startsWith('dmq-nav-item--l')) {
        this.navLevel = clsName.replace('dmq-nav-item--', '');
      }
    }

    // const toggleEl = this.toggleEl;
    const contentEl = this.contentEl;
    const linkEl = this.linkEl;
    const listEl = this.listEl;

    if (listEl && listEl.classList.contains('dmq-nav-list--menu')) {
      document.addEventListener('click', (e) => {
        if (!isIn(e.target as HTMLElement, listEl)) {
          listEl.querySelectorAll(`.dmq-nav-item.dmq-nav-item--${this.navLevel}`).forEach((ie) => {
            (ie as HTMLElement).classList.add('dmq-nav-item--deselecting');
            (ie as HTMLElement).classList.remove('dmq-nav-item--selected');
          });
        }
      });
    }

    /* if (toggleEl && contentEl) {
      toggleEl.onclick = (ev) => {
        if (getComputedStyle(toggleEl).display !== 'none') {
          ev.preventDefault();
          if (this.isExpanded) {
            this.navItemEl.classList.remove('dmq-nav-item--expanded');
            this.navItemEl.classList.add('dmq-nav-item--collapsed');
          } else {
            this.navItemEl.classList.add('dmq-nav-item--expanded');
            this.navItemEl.classList.remove('dmq-nav-item--collapsed');
          }
        }
      };
    } */

    if (linkEl && contentEl) {
      linkEl.onclick = (ev) => {

        ev.preventDefault();

        // For mobile
        if (this.isExpanded) {
          this.navItemEl.classList.remove('dmq-nav-item--expanded');
          this.navItemEl.classList.add('dmq-nav-item--collapsed');
        } else {
          this.navItemEl.classList.add('dmq-nav-item--expanded');
          this.navItemEl.classList.remove('dmq-nav-item--collapsed');
        }

        const isSelected = this.isSelected;
        const le = this.listEl;
        const isMenu = le ? le.classList.contains('dmq-nav-list--menu') : false;
        const isTab = le ? le.classList.contains('dmq-nav-list--tab') : false;
        if (le && (isMenu || isTab)) {

          const hasSelected = le.querySelectorAll(
            `.dmq-nav-item.dmq-nav-item--${this.navLevel}.dmq-nav-item--selected`).length > 0;

          le.querySelectorAll(`.dmq-nav-item.dmq-nav-item--${this.navLevel}`).forEach((ie) => {
            const nie = ie as HTMLElement;

            if (nie === this.navItemEl) {
              if (isSelected && isMenu) {
                nie.classList.add('dmq-nav-item--deselecting');
                nie.classList.remove('dmq-nav-item--selecting', 'dmq-nav-item--selected');
              } else if (hasSelected) {
                nie.classList.remove('dmq-nav-item--selecting', 'dmq-nav-item--deselecting');
                nie.classList.add('dmq-nav-item--selected');
              } else {
                nie.classList.remove('dmq-nav-item--deselecting');
                nie.classList.add('dmq-nav-item--selecting');
                nie.classList.add('dmq-nav-item--selected');
              }
            } else {
              nie.classList.remove('dmq-nav-item--selecting', 'dmq-nav-item--deselecting');
              nie.classList.remove('dmq-nav-item--selected');
            }

          });
        }
      };
    }
  }

  private elForLevel(cls: string): HTMLElement | null {
    if (this.navLevel) {
      return this.navItemEl.querySelector(`.${cls}.${cls}--${this.navLevel}`) as HTMLElement | null;
    }
    return null;
  }

  private get linkEl() {
    return this.elForLevel('dmq-nav-link');
  }

  private get contentEl() {
    return this.elForLevel('dmq-nav-content');
  }

  private get toggleEl() {
    return this.elForLevel('dmq-nav-toggle');
  }

  private get listEl() {
    return this.navItemEl.closest(`.dmq-nav-list.dmq-nav-list--${this.navLevel}`) as HTMLElement | null;
  }

  private get isExpanded() {
    return this.navItemEl.classList.contains('dmq-nav-item--expanded');
  }

   private get isSelected() {
    return this.navItemEl.classList.contains('dmq-nav-item--selected');
  }

}
