export interface Category {
  id: number;
  name: string;
  children: Category[];
}

export class CategoryFilter {
  public id: number;
  public name: string;
  public children: CategoryFilter[];
  private isApplied: boolean = false;
  private isApply: boolean = false;

  public get applied() {
    if (this.children.length > 0) {
      for (const c of this.children) {
        if (!c.applied) {
          return false;
        }
      }
      return true;
    }

    return this.isApplied;
  }

  public set applied(v: boolean) {
    if (this.children.length > 0) {
      for (const c of this.children) {
        c.applied = v;
      }
    } else {
      this.isApplied = v;
    }
  }

   public get apply() {
    if (this.children.length > 0) {
      for (const c of this.children) {
        if (!c.apply) {
          return false;
        }
      }
      return true;
    }

    return this.isApply;
  }

  public set apply(v: boolean) {
    if (this.children.length > 0) {
      for (const c of this.children) {
        c.apply = v;
      }
    } else {
      this.isApply = v;
    }
  }

  public get hasApply() {
    if (this.children.length > 0) {
      for (const c of this.children) {
        if (c.hasApply) {
          return true;
        }
      }
      return false;
    }

    return this.isApply;
  }

  public constructor(category: Category, appliedCategoryIds: number[]) {
    this.id = category.id;
    this.name = category.name;
    this.children = category.children.map((c) => new CategoryFilter(c, appliedCategoryIds));
    if (this.children.length === 0) {
      this.isApplied = appliedCategoryIds.indexOf(category.id) > -1;
      this.isApply = this.isApplied;
    }
  }
}
