





































































import { Vue, Component, Prop } from 'vue-property-decorator';
import api from '@/api';

@Component
export default class DmqNewsletterUnubscribe extends Vue {

  @Prop({
    type: Array,
  })
  private readonly contacts?: [];

  @Prop({
    type: Number,
  })
  private readonly mailingId?: number;

  @Prop({
    type: String,
  })
  private readonly email?: string;

  private showUnsubscribing: boolean = false;
  private reason: string = '';
  private otherReason: string = '';
  private loading: boolean = false;
  private message: string = '';
  private error: boolean = false;

  private showReason() {
    this.showUnsubscribing = !this.showUnsubscribing;
  }

  private unsubscribeReason() {
    this.loading = true;

    if(this.reason !== 'other') {
      this.otherReason = '';
    }

    api.post('newsletter/unsubscribe_reason', {
      reason: this.reason,
      otherReason: this.otherReason,
      mailingId: this.mailingId,
      email: this.email
    }).then(() => {
      this.error = false;
      this.message = 'Votre désinscription s\'est effectuée avec succès !';
      this.loading = false;
    }, () => {
      this.error = true;
      this.message = 'Un problème est survenu lors de votre désinscription. Merci de prendre contact avec nous.';
      this.loading = false;
    });
  }

  private comeBack() {
    window.location.assign('/');
  }
}

