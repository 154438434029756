















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import store from '@/store/filters';
import FilterModule from '@/store/modules/filter-module';
import DmqFilterTag from '@/components/DmqFilterTag.vue';
import DmqFilter from '@/components/DmqFilter.vue';
import DmqCategoryFilter from '@/components/DmqCategoryFilter.vue';
import DmqAttributeFilter from '@/components/DmqAttributeFilter.vue';
import DmqPriceFilter from '@/components/DmqPriceFilter.vue';
import { CategoryFilter } from '@/models/category';
import { AttributeValueFilter } from '@/models/attribute-value';
import { AttributeFilter } from '@/models/attribute';

const filterModule = getModule(FilterModule, store);

@Component({
  components: {
    DmqFilterTag,
    DmqFilter,
    DmqCategoryFilter,
    DmqAttributeFilter,
    DmqPriceFilter,
  },
})
export default class DmqFilters extends Vue {

  @Prop({
    required: true,
    type: String,
  })
  public categoryFilterTitle?: string;

  @Prop({
    required: true,
    type: String,
  })
  public priceFilterTitle?: string;

  public get filterModule() {
    return filterModule;
  }

  public get categoryExpanded() {
    return true;
  }

  public get categories() {
    return filterModule.categories;
  }

  public get appliedCategories() {
    return filterModule.appliedCategories;
  }

  public get attributes() {
    return filterModule.attributes;
  }

  public get appliedAttributes() {
    return filterModule.appliedAttributes;
  }

  public get hasAppliedFilters() {
    return filterModule.hasAppliedFilters;
  }

  public get hasFilterToApply() {
    return filterModule.hasFiltersToApply;
  }

  private get autoApply(): boolean {
    return getComputedStyle(this.$refs['apply-button'] as HTMLElement).display === 'none';
  }

  private get priceFilterExpanded() {
    return filterModule.minPrice !== filterModule.appliedMinPriceFilter ||
        filterModule.maxPrice !== filterModule.appliedMaxPriceFilter;
  }

  private applyFilters() {
    if (this.autoApply) {
      filterModule.applyFilters();
    }
  }

  private clearCategory() {
    for (const c of this.appliedCategories) {
      filterModule.checkCategory({
        category: c,
        selected: false,
      });
    }
    filterModule.applyFilters();
  }

  private clearAttribute(attribute: AttributeFilter) {
    for (const av of attribute.values) {
        filterModule.checkAttributeValue({
        value: av,
        selected: false,
      });
    }
    filterModule.applyFilters();
  }

  private clearMinPrice() {
    filterModule.changePricesFilter({
      minPriceFilter: filterModule.minPrice,
      maxPriceFilter: filterModule.maxPriceFilter,
    });
    filterModule.applyFilters();
  }

  private clearMaxPrice() {
    filterModule.changePricesFilter({
      minPriceFilter: filterModule.minPriceFilter,
      maxPriceFilter: filterModule.maxPrice,
    });
    filterModule.applyFilters();
  }

  private clearAll() {
    filterModule.clearFilters();
    filterModule.applyFilters();
  }

  private apply() {
    filterModule.applyFilters();
  }

}
