

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Category, CategoryFilter } from '@/models/category';
import store from '@/store/filters';
import FilterModule from '@/store/modules/filter-module';

const filterModule = getModule(FilterModule, store);

@Component({
  name: 'dmq-category-filter',
})
export default class DmqCategoryFilter extends Vue {
  @Prop({
    required: true,
  })
  public category?: CategoryFilter;

  private expanded: boolean = false;

  public get apply() {
    return !!this.category && this.category!.apply;
  }

  public set apply(v: boolean) {
    if (this.category) {
      filterModule.checkCategory({
        category: this.category,
        selected: v,
      });
      this.$emit('apply');
    }
  }

  protected mounted() {
    this.expanded = this.category ? this.category.hasApply : false;
  }

  private toggle() {
    this.expanded = !this.expanded;
  }

}
