import numeral from 'numeral';

export default class DmqRemainingTime {
  constructor(private el: HTMLElement) {
    let remainingSeconds = parseInt(el.dataset.remainingSeconds || '0', 10);
    const dayEl = el.querySelector<HTMLElement>(el.dataset.dayEl || '');
    const hourEl = el.querySelector<HTMLElement>(el.dataset.hourEl || '');
    const minuteEl = el.querySelector<HTMLElement>(el.dataset.minuteEl || '');
    const secondEl = el.querySelector<HTMLElement>(el.dataset.secondEl || '');

    if (hourEl && minuteEl && secondEl && dayEl) {
      if (remainingSeconds > 0) {
        const intHandle = setInterval(() => {
          let day = '';
          let hour = '';
          let minute = '';
          let second = '';

          second = numeral(Math.floor(remainingSeconds % 60)).format('00');
          minute = numeral(Math.floor((remainingSeconds / 60) % 60)).format('00');
          hour = numeral(Math.floor((remainingSeconds / (60 * 60) % 24))).format('00');
          day = numeral(Math.floor(remainingSeconds / (3600 * 24) )).format('0');

          secondEl.innerText = second;
          minuteEl.innerText = minute;
          hourEl.innerText = hour;
          dayEl.innerText = day;

          if (day === '0') {
            secondEl.hidden = false;
            minuteEl.hidden = false;
            hourEl.hidden = false;
            dayEl.hidden = true;

          } else {
            secondEl.hidden = true;
            minuteEl.hidden = true;
            hourEl.hidden = true;
            dayEl.hidden = false;
          }

          if (remainingSeconds <= 0) {
            clearInterval(intHandle);
          }
          remainingSeconds -= 1;
        }, 1000);
      } else {
        secondEl.innerText = '0';
        minuteEl.innerText = '0';
        hourEl.innerText = '0';
        dayEl.innerText = '0';
      }

    }


  }
}
