































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import DmqModal from '@/components/DmqModal.vue';
import axios, { AxiosRequestConfig } from 'axios';
import { SearchResults, ProductSearchResult } from "@/models/search";

@Component({
  components: {
    DmqModal,
  },
})

export default class DmqSearch extends Vue {

  private shown: boolean = false;
  private query: string = '';
  private request:any = null;
  public results: SearchResults | null = null;
  public selectedCategory: number = -1;
  public selectedRetailer: number = -1;
  public nbProductByCategory: number = -1;
  public showAllCategories: string = '';
  private loading: boolean = false;
  private allRegions: boolean = true;

  private sendRequest() {
    this.cancelRequest();
    this.makeRequest(this.query);
  }

  private cancelRequest() {
    if (this.request) {
      this.request.cancel();
      this.request = null;
    }
  }

  private makeRequest(query: string) {
    this.loading = true;
    const axiosSource = axios.CancelToken.source();
    this.request = { cancel: axiosSource.cancel  };

    const axiosAPI = axios.create({
      cancelToken: axiosSource.token,
      baseURL: process.env.VUE_APP_DMQ_API_URL,
    });

    axiosAPI.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
      config.headers['Content-Type'] = 'application/dmq+json';
      config.headers['Accept'] = 'application/dmq+json';
      config.headers['X-Requested-With'] = 'XMLHttpRequest';
      return config;
    });

    axiosAPI.get<SearchResults>('search/', {
      params: {
        'query': query,
        'allRegions': this.allRegions
      }
    }).then((response) => {
      this.request = null;
      this.results = response.data;
      this.selectedRetailer = -1;
      this.selectedCategory = -1;
      this.showAllCategories = '/shop?search=' + this.query;

      if (this.results && this.results.categories) {
        const cat = this.results.categories.find(x => {
          return x.url !== ''
        });
        if (cat) {
          this.selectedCategory = cat.id;
          this.nbProductByCategory = cat.nbProducts;
          this.showAllCategories = cat.url;
        }
      }

      if (this.results && this.results.retailers && this.selectedCategory === -1) {
        const retailer = this.results.retailers[0];
        if (retailer) {
          this.selectedRetailer = retailer.id;
          this.nbProductByCategory = retailer.nbProducts;
          this.showAllCategories = retailer.url;
        }
      }

      if (this.selectedRetailer === -1 && this.selectedCategory === -1) {
        this.nbProductByCategory = this.results && this.results.nbProducts ? this.results.nbProducts : 0;
      }

      this.loading = false;

    }).catch((err) => {
      if (axios.isCancel(err)) {
        console.log("Request cancelled", err.message);
      }
    });
  }

  private productsSelectionCategory(id: number, nbProducts: number, url: string) {
    this.selectedRetailer = -1;
    if (this.selectedCategory !== id) {
      this.selectedCategory = id;
      this.nbProductByCategory = nbProducts;
      this.showAllCategories = url;
    }
  }

  private productsSelectionRetailer(id: number, nbProducts: number, url: string) {
     this.selectedCategory = -1;
    if (this.selectedRetailer !== id) {
      this.selectedRetailer = id;
      this.nbProductByCategory = nbProducts;
      this.showAllCategories = url;
    }
  }

  private get haveResults() {
    return this.results && (
      this.results.products.length ||
      this.results.categories.length ||
      this.results.brands.length ||
      this.results.retailers.length
    );
  }

  private get categories() {
    if (this.results) {
      return this.results.categories;
    }
    return [];
  }

  private get products() {
    if (this.results && this.results.products) {
      if (this.selectedCategory !== -1) {
        return this.results.products.filter(x => {
          return x.categoryId === this.selectedCategory
        });

      } else if (this.selectedRetailer !== -1) {
        return this.results.products.filter(x => {
          return x.retailerId === this.selectedRetailer
        });
      } else {
        return this.results.products
      }
    }
    return [];
  }

  private get brands() {
    if (this.results) {
      return this.results.brands;
    }
    return [];
  }

  private get retailers() {
    if (this.results) {
      return this.results.retailers;
    }
    return [];
  }

  private search() {
    if(this.query.length >= 3) {
      this.sendRequest();
    }
  }

  private enter() {
    if (this.query) {
      window.location.assign(this.showAllCategories);
    }
  }

  private onpenModalSearch() {
    if (this.query) {
      this.shown = true;
      this.sendRequest();
    }
  }

  private clear() {
    this.shown = false;
    this.query = '';
    this.allRegions = true;
    this.sendRequest();
  }

  private changeRegions() {
    this.search();
  }

}
