













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DmqFilter extends Vue {

  @Prop({
    required: true,
    type: String,
  })
  public title?: string;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  public isExpanded?: boolean;

  private expanded: boolean = false;

  protected mounted() {
    this.expanded = this.isExpanded || false;
  }

  private toggle() {
    this.expanded = !this.expanded;
  }
}
