export default class DmqToggler {

  private toggled = false;

  constructor(
    private readonly togglerEls: HTMLElement[],
    private readonly toggledEl: HTMLElement,
    private readonly togglerToggledClass: string | null,
    private readonly toggledToggledClass: string | null,
  ) {
    togglerEls.forEach((el: HTMLElement) => {
      el.onclick = () => {
        this.toggle();
      };
    });
  }

  private toggleOn() {
    this.toggled = true;
    if (this.togglerToggledClass) {
      const t = this.togglerToggledClass;
      this.togglerEls.forEach((el: HTMLElement) => {
        el.classList.add(t);
      });
    }
    if (this.toggledToggledClass) {
      this.toggledEl.classList.add(this.toggledToggledClass);
    }
  }

  private toggleOff() {
    this.toggled = false;
    if (this.togglerToggledClass) {
      const t = this.togglerToggledClass;
      this.togglerEls.forEach((el: HTMLElement) => {
        el.classList.remove(t);
      });
    }
    if (this.toggledToggledClass) {
      this.toggledEl.classList.remove(this.toggledToggledClass);
    }
  }

  private toggle() {
    if (!this.toggled) {
      this.toggleOn();
    } else {
      this.toggleOff();
    }
  }

}
