document.querySelectorAll('.dmq-accordion').forEach((el) => {

  const accEl = el as HTMLElement;
  const headEL = accEl.querySelector('.dmq-accordion__head') as HTMLElement;
  headEL.addEventListener('click', () => {

    document.querySelectorAll('.dmq-accordion').forEach((lel) => {
      const curAccEl = lel as HTMLElement;
      if (curAccEl === accEl) {
        curAccEl.classList.toggle('dmq-accordion--expanded');
      } else {
        curAccEl.classList.remove('dmq-accordion--expanded');
      }
    });

  });
});
