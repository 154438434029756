











import '@/styles/dmq-modal.scss';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DmqModal extends Vue {

  protected mounted() {
    this.$emit('mounted');
  }

  protected destroyed() {
    this.$emit('destroyed');
  }
}

