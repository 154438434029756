import Vue from 'vue';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { SaleRegion, SaleZone } from '@/models/sale-region';
import api from '@/api';


@Module({ namespaced: true, name: 'salesRegions' })
export default class SalesRegionsModule extends VuexModule {

  public salesZones: SaleZone[] = [];

  public get hasNoSelection() {
    return this.salesZones.filter((sz) => {
      return sz.regions.filter((sr) => sr.checked).length > 0;
    }).length === 0;
  }

  @Mutation
  public fetched(salesZones: SaleZone[]) {
    this.salesZones = salesZones;
  }

  @Action
  public selectAll() {
    for (const zone of this.salesZones) {
      for (let i = 0; i < zone.regions.length; i++) {
        const r = zone.regions[i];
        r.checked = true;
        Vue.set(zone.regions, i, r);
      }
    }
  }

  @Action
  public async saveRegions() {
    let data: Array<{id: number}> = [];
    for (const z of this.salesZones) {
      data = data.concat(
        z.regions.filter((r: SaleRegion) => {
          return r.checked;
        }).map((r: SaleRegion) => {
          return {id: r.id};
        }),
      );
    }
    const response = await api.post('my/regions', data);
    this.context.commit('fetched', response.data);
    return response;
  }

  @Action
  public async selectAllAndSave() {
    let data: Array<{id: number}> = [];
    for (const z of this.salesZones) {
      data = data.concat(
        z.regions.map((r: SaleRegion) => {
          return {id: r.id};
        }),
      );
    }
    const response = await api.post('my/regions', data);
    this.context.commit('fetched', response.data);
    return response;
  }

}
