export interface AttributeValue {
  id: number;
  name: string;
}

export class AttributeValueFilter {
  public id: number;
  public name: string;
  public apply: boolean;
  public applied: boolean;

  constructor(value: AttributeValue, appliedValueIds: number[]) {
    this.id = value.id;
    this.name = value.name;
    this.applied = appliedValueIds.indexOf(value.id) > -1;
    this.apply = this.applied;
  }
}
