


























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import DmqCheckoutAddressRadios from '@/components/DmqCheckoutAddressRadios.vue';
import DmqAddressForm from '@/components/DmqAddressForm.vue';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import AddressesModule from '@/store/modules/addresses-module';
import CartModule from '@/store/modules/cart-module';
import { Address } from '@/models/address';

const addressesModule = getModule(AddressesModule, store);
const cartModule = getModule(CartModule, store);

@Component({
  components: {
    DmqCheckoutAddressRadios,
    DmqAddressForm,
  },
})
export default class DmqCheckoutAddresses extends Vue {

  private deliveryFormShown = false;
  private loading = false;
  private invoiceFormShown = false;

  private deliveryAddress: Address | null = null;
  private selectedDeliveryAddress: Address | null = null;

  private invoiceAddress: Address | null = null;
  private selectedInvoiceAddress: Address | null = null;

  @Prop()
  private readonly customClasses!: string;

  @Prop()
  private readonly deliveryPartnerId!: number;

  @Prop()
  private readonly invoicePartnerId!: number;

  @Prop()
  private readonly csrfToken!: string;

  private get deliveryAddresses() {
    return addressesModule.addresses.filter((a: Address) => {
      return a.type === 'delivery';
    });
  }

  private get invoiceAddresses() {
    return addressesModule.addresses.filter((a: Address) => {
      return a.type === 'invoice';
    });
  }

  private get deliveryId() {
    return this.selectedDeliveryAddress ? this.selectedDeliveryAddress.id : null;
  }

   private get invoiceId() {
    const invoiceId = (this.selectedInvoiceAddress ? this.selectedInvoiceAddress.id : null);
    return invoiceId ? invoiceId : this.deliveryId;
  }

  private mounted() {
    const sd = this.deliveryAddresses.filter((a) => {
      return a.id === this.deliveryPartnerId;
    });
    this.selectedDeliveryAddress = sd.length > 0 ? sd[0] : null;
    const id = this.invoiceAddresses.filter((a) => {
      return a.id === this.invoicePartnerId;
    });
    this.selectedInvoiceAddress = id.length > 0 ? id[0] : null;
  }

  private addDeliveryAddress() {
    this.deliveryAddress = null;
    this.deliveryFormShown = true;
  }

  private editDeliveryAddress(address: Address) {
    this.deliveryAddress = address;
    this.deliveryFormShown = true;
  }

  private deleteDeliveryAddress(address: Address) {
    this.loading = true;
    if (this.deliveryId === address.id) {
      this.selectedDeliveryAddress = null;
    }
    addressesModule.deleteAddress(address).then(() => {
      this.loading = false;
    });
  }

  private saveDeliveryAddress(address: Address) {
    address.type = 'delivery';
    this.loading = true;
    if (address.id != null) {
      addressesModule.editAddress({
        oldAddress: this.deliveryAddress!,
        newAddress: address,
      }).then(() => {
        this.deliveryFormShown = false;
        this.loading = false;
        this.deliveryAddress = null;
        if (this.selectedDeliveryAddress && this.selectedDeliveryAddress.id === address.id) {
            this.selectedDeliveryAddress = this.deliveryAddresses.filter((a) => {
            return a.id === address.id;
          })[0];
        }
      });
    } else {
      addressesModule.addAddress(address).then(() => {
        this.deliveryFormShown = false;
        this.loading = false;
        if (!this.selectedDeliveryAddress && this.deliveryAddresses.length) {
          this.selectedDeliveryAddress = this.deliveryAddresses[this.deliveryAddresses.length - 1];
        }
      });
    }

  }

  private addInvoiceAddress() {
    this.invoiceAddress = null;
    this.invoiceFormShown = true;
  }

  private editInvoiceAddress(address: Address) {
    this.invoiceAddress = address;
    this.invoiceFormShown = true;
  }

  private deleteInvoiceAddress(address: Address) {
    this.loading = true;
    if (this.invoiceId === address.id) {
      this.selectedInvoiceAddress = null;
    }
    addressesModule.deleteAddress(address).then(() => {
      this.loading = false;
    });
  }

  private saveInvoiceAddress(address: Address) {
    address.type = 'invoice';
    this.loading = true;
    if (address.id != null) {
      addressesModule.editAddress({
        oldAddress: this.invoiceAddress!,
        newAddress: address,
      }).then(() => {
        this.invoiceFormShown = false;
        this.loading = false;
        this.invoiceAddress = null;
        if (this.selectedInvoiceAddress && this.selectedInvoiceAddress.id === address.id) {
            this.selectedInvoiceAddress = this.invoiceAddresses.filter((a) => {
            return a.id === address.id;
          })[0];
        }
      });
    } else {
      addressesModule.addAddress(address).then(() => {
        this.invoiceFormShown = false;
        this.loading = false;
        if (!this.selectedInvoiceAddress && this.invoiceAddresses.length) {
          this.selectedInvoiceAddress = this.invoiceAddresses[this.invoiceAddresses.length - 1];
        }
      });
    }
  }

  private selectDeliveryAddress(address: Address) {
    if (!this.csrfToken && address)  {
      addressesModule.setDefaultShipping(address.id!);
    }
  }

  private selectInvoiceAddress(address: Address) {
    let id = null;
    if (address) {
      id = address.id!;
    } else {
      id = this.deliveryId;
    }
    if (!this.csrfToken && id) {
      addressesModule.setDefaultInvoice(id);
    }
  }
}
