import { getModule } from 'vuex-module-decorators';
import store from '@/store/filters';
import FilterModule from '@/store/modules/filter-module';

const filterModule = getModule(FilterModule, store);

export default class DmqProductSortSelect {
  constructor(private el: HTMLSelectElement) {
    el.onchange = () => {
      filterModule.changeSortValue(el.options[el.selectedIndex].value);
    };
  }

}
