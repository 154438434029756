import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { MyData } from '@/models/my-data';
import api from '@/api';


@Module({ namespaced: true, name: 'myData' })
export default class MyDataModule extends VuexModule {

  public myData: MyData | null = null;

  @Mutation
  public myDataFetched(myData: MyData) {
    this.myData = myData;
  }

  @Action
  public async editMyData(myData: MyData) {
    return await api.put(`mydata/`, myData).then((r) => {
      this.context.commit('myDataFetched', r.data);
    });
  }

}
