
























import { Component, Vue } from 'vue-property-decorator';
import VueSlider from 'vue-slider-component';
import '@/styles/vue-slider.scss';
import { getModule } from 'vuex-module-decorators';
import store from '@/store/filters';
import FilterModule from '@/store/modules/filter-module';

const filterModule = getModule(FilterModule, store);

@Component({
  components: {
    VueSlider,
  },
})
export default class DmqPriceFilter extends Vue {

  public get min() {
    return filterModule.minPrice;
  }

  public get max() {
    return filterModule.maxPrice;
  }

  public get marks() {
    return [filterModule.minPrice, filterModule.maxPrice];
  }

  public get prices() {
    return [filterModule.minPriceFilter, filterModule.maxPriceFilter];
  }

  public set prices(prices: number[]) {
    filterModule.changePricesFilter({
      minPriceFilter: prices[0],
      maxPriceFilter: prices[1],
    });
    this.$emit('apply');
  }

  public formatTooltip(v: string): string {
    return `CHF ${v}`;
  }

}

