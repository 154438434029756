import Vue from 'vue';
import Vuex from 'vuex';
import SearchModule from './modules/search-module';
import CartModule from '@/store/modules/cart-module';
import AddressesModule from '@/store/modules/addresses-module';
import WishlistModule from '@/store/modules/wishlist-module';
import MyDataModule from '@/store/modules/my-data-module';
import SalesRegionsModule from '@/store/modules/sales-regions-module';

Vue.use(Vuex);
Vue.config.devtools = true

const store = new Vuex.Store({
  state: {},
  modules: {
    search: SearchModule,
    cart: CartModule,
    wishlist: WishlistModule,
    addresses: AddressesModule,
    myData: MyDataModule,
    salesRegions: SalesRegionsModule,
  },
});

export default store;
