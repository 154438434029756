import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Address } from '@/models/address';
import { Country } from '@/models/country';
import api from '@/api';


@Module({ namespaced: true, name: 'addresses' })
export default class AddressesModule extends VuexModule {

  public addresses: Address[] = [];
  public countries: Country[] = [];
  public error: any = null;

  @Mutation
  public countriesFetched(countries: Country[]) {
    this.countries = countries;
  }

  @Mutation
  public addressesFetched(addresses: Address[]) {
    this.addresses = addresses;
  }

  @Mutation
  public addressAdded(address: Address) {
    this.addresses.push(address);
  }

  @Mutation
  public addressEdited({oldAddress, newAddress}: {oldAddress: Address, newAddress: Address}) {
    const index = this.addresses.indexOf(oldAddress);
    if (index > -1) {
      this.addresses.splice(index, 1, newAddress);
    }
  }

  @Mutation
  public hadError(error: any) {
    console.log(error);
    this.error = error;
  }

  @Action
  public async getCountries() {
      try {
        const r = await api.get('country/', {});
        this.context.commit('countriesFetched', r.data);
      } catch (e) {
        // Nothing to do
      }
  }

  @Action
  public async deleteAddress(address: Address) {
    try {
      const r = await api.delete(`address/${address.id}`);
      this.context.commit('addressesFetched', r.data);
    } catch (e) {
      this.context.commit('hadError', e.response);
    }
  }

  @Action
  public async addAddress(address: Address) {
    try {
      const r = await api.post('address', address);
      this.context.commit('addressesFetched', r.data);
    } catch (e) {
      this.context.commit('hadError', e.response);
    }
  }

  @Action
  public async editAddress({oldAddress, newAddress}: {oldAddress: Address, newAddress: Address}) {
    try {
      const r = await api.put(`address/${newAddress.id}`, newAddress);
      this.context.commit('addressesFetched', r.data);
    } catch (e) {
      this.context.commit('hadError', e.response);
    }
  }

  @Action
  public async setDefaultInvoice(id: number) {
    await api.put('address/default_invoice', {
      id,
    });
  }

  @Action
  public async setDefaultShipping(id: number) {
    await api.put('address/default_shipping', {
      id,
    });
  }

}
