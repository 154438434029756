import { AttributeValue, AttributeValueFilter } from '@/models/attribute-value';

export interface Attribute {
  id: number;
  name: string;
  values: AttributeValue[];
}

export class AttributeFilter {
  public id: number;
  public name: string;
  public values: AttributeValueFilter[];

  public get hasApply() {
    for (const av of this.values) {
      if (av.apply) {
        return true;
      }
    }
    return false;
  }

  constructor(attribute: Attribute, appliedValueIds: number[]) {
    this.id = attribute.id;
    this.name = attribute.name;
    this.values = attribute.values.map((v) => new AttributeValueFilter(v, appliedValueIds));
  }
}
