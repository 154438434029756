

















import { Vue, Component, Prop } from 'vue-property-decorator';
import api from '@/api';

@Component
export default class DmqNewsletterSubscribe extends Vue {

  @Prop({
    type: String,
  })
  private readonly email?: string;

  @Prop({
    type: String,
  })
  private readonly title?: string;

  @Prop({
    type: String,
  })
  private readonly description?: string;

  @Prop({
    type: String,
  })
  private readonly placeholder?: string;

  @Prop({
    type: String,
  })
  private readonly sendActionLabel?: string;

    @Prop({
    type: String,
  })
  private readonly subscribeActionLabel?: string;

  private emailModel: string = '';
  private error: boolean = false;
  private message: string = '';
  private loading: boolean = false;

  private subscribe() {
    this.loading = true;
    api.post('newsletter/subscribe', {
      email: this.email || this.emailModel,
    }).then(() => {
      this.error = false;
      this.message = 'Inscription effectuée !';
      this.loading = false;
    }, () => {
      this.error = true;
      this.message = 'Echec de l\'inscription, veuillez vérifier l\'adresse e-mail.';
      this.loading = false;
    });
  }
}

