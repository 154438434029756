var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dmq-price-filter"},[_c('vue-slider',{ref:"slider",attrs:{"lazy":true,"min":_vm.min,"max":_vm.max,"drag-on-click":true,"contained":true,"marks":_vm.marks,"enableCross":false,"tooltip":"always","tooltipFormatter":_vm.formatTooltip},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var active = ref.active;
var value = ref.value;
var pos = ref.pos;
var label = ref.label;
return [_c('div',{class:[
        'dmq-slider-mark-label',
        { 'dmq-slider-mark-label--right': value === _vm.min,
          'dmq-slider-mark-label--left': value === _vm.max,
        }]},[_vm._v("CHF "+_vm._s(value))])]}}]),model:{value:(_vm.prices),callback:function ($$v) {_vm.prices=$$v},expression:"prices"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }