






















































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { Address } from '@/models/address';
import { Country } from '@/models/country';
import AddressesModule from '@/store/modules/addresses-module';
import {getModule} from "vuex-module-decorators";
import store from "@/store";
import { email } from 'vuelidate/lib/validators';

const addressesModule = getModule(AddressesModule, store);

function notBlank(value: any): boolean {
  return (value || '').trim().length > 0;
}

function notBlankInt(value: any): boolean {
  return (value || 0) > 0;
}

@Component({
  mixins: [validationMixin],
  validations: {
    name: {
      notBlank,
    },
    street: {
      notBlank,
    },
    zip: {
      notBlank,
    },
    city: {
      notBlank,
    },
    countryId: {
      notBlankInt,
    },
    phone: {
      notBlank,
    },
    email: {
      notBlank,
      email
    },
  },
})


export default class DmqAddressForm extends Vue {

  @Prop()
  private readonly formTitle!: string;

  @Prop()
  private readonly disabled!: boolean;

  @Prop()
  private readonly cancelEnabled!: boolean;

  @Prop()
  private readonly address!: Address | null;

  @Prop()
  private readonly typeAddress!: string;

  private id: number | null = null;
  private name: string = '';
  private street: string = '';
  private street2: string = '';
  private zip: string = '';
  private city: string = '';
  private countryId: number | null = null;
  private phone: string = '';
  private email: string = '';

  private countries: Country[] = [];

  private validForm(invName:boolean,
                    invStreet:boolean,
                    invZip:boolean,
                    invCity:boolean,
                    invCountryId:boolean,
                    invPhone:boolean,
                    invEmail:boolean) {
    const invalid: boolean = invName || invStreet || invZip || invCity || invCountryId || invPhone;
    return this.typeAddress === 'delivery' ? invalid : invalid || invEmail;
  }

  @Watch('address')
  private updateAddress(val: Address | null) {
    if (val) {
      this.id = val.id ? val.id : null;
      this.name = val.name ? val.name : '';
      this.street = val.street ? val.street : '';
      this.street2 = val.street2 ? val.street2 : '';
      this.zip = val.zip ? val.zip : '';
      this.city = val.city ? val.city : '';
      this.countryId = val.countryId ? val.countryId : null;
      this.phone = val.phone ? val.phone : '';
      this.email = val.email ? val.email : '';
    } else {
      this.id = null;
      this.name = '';
      this.street = '';
      this.street2 = '';
      this.zip = '';
      this.city = '';
      this.countryId = null;
      this.phone = '';
      this.email = '';
    }
  }

  private getValues() {
    addressesModule.getCountries().then(() => {
      const countries = addressesModule.countries;
      if(this.typeAddress === 'delivery') {
        this.countries = countries.filter(c => c.code === 'CH' || c.code === 'LI');
      } else {
        this.countries = countries;
      }

      this.updateAddress(this.address);
    });
  }

  private mounted() {
    this.getValues();
  }

  private submit() {
    this.$emit('submit', {
        id: this.id,
        name: this.name,
        street: this.street,
        street2: this.street2,
        zip: this.zip,
        city: this.city,
        countryId: this.countryId,
        phone: this.phone,
        email: this.email,
     });
  }
}

