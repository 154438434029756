


































import { Component, Vue, Prop } from 'vue-property-decorator';
import SalesRegionsModule from '@/store/modules/sales-regions-module';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import { SaleZone } from '@/models/sale-region';

const salesRegionsModule = getModule(SalesRegionsModule, store);

@Component
export default class DmqMyRegions extends Vue {

  private error: string = '';
  private loading: boolean = false;
  private expandedZoneIds: number[] = [];

  private get saveDisabled() {
    return salesRegionsModule.hasNoSelection;
  }

  public get zones() {
    return salesRegionsModule.salesZones;
  }

  private mounted() {
    for (const z of this.zones) {
      if (z.regions.filter((sr) => sr.checked).length > 0) {
        this.expandedZoneIds.push(z.id);
      }
    }
    if (this.expandedZoneIds.length === 0 && this.zones.length > 0) {
      this.expandedZoneIds.push(this.zones[0].id);
    }
  }

  private selectAll() {
    salesRegionsModule.selectAll();
    this.save();
  }

  private save() {
    this.loading = true;
    salesRegionsModule.saveRegions().then(() => {
      window.location.reload();
    }, () => {
      this.loading = false;
      this.error = 'Quelque chose s\'est mal passé lors de l\'enregistrement, veuillez rééssayer.';
    });
  }

  private toggleZone(zone: SaleZone) {
    if (this.zoneCollapsed(zone)) {
      this.expandedZoneIds.push(zone.id);
    } else {
      this.expandedZoneIds.splice(this.expandedZoneIds.indexOf(zone.id));
    }
  }

  private zoneExpanded(zone: SaleZone) {
    return this.expandedZoneIds.indexOf(zone.id) !== -1;
  }

  private zoneCollapsed(zone: SaleZone) {
    return !this.zoneExpanded(zone);
  }

}
