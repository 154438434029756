import Vue from 'vue';
import Vuex from 'vuex';
import FilterModule from '@/store/modules/filter-module';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  modules: {
    filter: FilterModule,
  },
});

export default store;
