
































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Address } from '@/models/address';

@Component
export default class DmqCheckoutAddressRadios extends Vue {

  @Prop()
  private readonly addresses!: Address[];

  @Prop()
  private readonly name!: string;

  @Prop()
  private readonly value!: Address;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  private readonly showEmptyRadio!: boolean;

  @Prop({
    required: false,
    type: String,
    default: '',
  })
  private readonly emptyRadioLabel!: string;

  private get selectedAddress() {
    return this.value;
  }

  private set selectedAddress(val: Address) {
    this.$emit('input', val);
  }
}
