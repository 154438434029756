









import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import CartModule from '@/store/modules/cart-module';
import store from '@/store';
const cartModule = getModule(CartModule, store);

@Component({})
export default class DmqCartIcon extends Vue {
  protected get cartQuantity() {
    return cartModule.cart.cartQuantity;
  }

  private get embed() {
    return cartModule.cart.embed ? cartModule.cart.embed : false;
  }

  protected get linkCart() {
    let link = "/shop/cart";
    if (this.embed) {
     link = "/shop/cart_embed/" + cartModule.cart.id;
    }

    return link;
  }

}
