import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import {Cart, CartItemToUpdate, CartPaymentMedthod, CartRetailerSectionToUpdate} from '@/models/cart';
import PromoCode from '@/models/promo-code';
import api from '@/api';

@Module({ namespaced: true, name: 'cart' })
export default class CartModule extends VuexModule {
  public isFetching: boolean = false;
  public isShown: boolean = false;
  public promoCode: PromoCode = {
    code: "",
    retCode: -1,
    message: "",
    reduction: 0.00,
    typeReduction: ""
  };
  public cart: Cart = {
    retailerSections: [],
    amountTotal: 0,
    amountSubtotalTotal: 0,
    intendedDeliveryFees: 0,
    deliveryFeesTotal: 0,
    paymentMethod: '',
    cartQuantity: 0,
    promocode: this.promoCode,
    updated: false,
    reduction: 0,
    embed: false,
    hasPromocode: false,
    codePromo: '',
    codePromoRetCode: 0,
    codePromoMessage: '',
    codePromoReduction: 0,
    codePromoTypeReduction: ''
  };

  @Mutation
  public shown() {
    this.isShown = true;
  }

  @Mutation
  public hidden() {
    this.isShown = false;
  }

  @Action
  public show() {
    this.context.commit('shown');
  }

  @Action
  public hide() {
    this.context.commit('hidden');
  }

  @Mutation
  public fetching() {
    this.isFetching = true;
  }

  @Mutation
  public fetched(cart: Cart) {
    this.cart = {
      id: cart.id,
      retailerSections: cart.retailerSections || [],
      amountTotal: cart.amountTotal || 0,
      amountSubtotalTotal: cart.amountSubtotalTotal || 0,
      intendedDeliveryFees: cart.intendedDeliveryFees || 0,
      deliveryFeesTotal: cart.deliveryFeesTotal || 0,
      paymentMethod: cart.paymentMethod,
      cartQuantity: cart.cartQuantity || 0,
      billingAddressId: cart.billingAddressId,
      shippingAddressId: cart.shippingAddressId,
      updated: cart.updated,
      promocode: cart.promocode,
      reduction: cart.reduction,
      embed: cart.embed,
      hasPromocode: cart.hasPromocode,
      codePromo: cart.codePromo,
      codePromoRetCode: cart.codePromoRetCode,
      codePromoTypeReduction: cart.codePromoTypeReduction,
      codePromoReduction: cart.codePromoReduction,
      codePromoMessage: cart.codePromoMessage
    };
    this.isFetching = false;
  }

  @Action
  public async add(item: CartItemToUpdate) {
    this.context.commit('fetching');
    try {
      const r = await api.post('cart', item);
      this.context.commit('fetched', r.data);
    } catch (e) {
      // TODO: Store error for display!
      this.context.commit('fetched', this.cart);
    }
  }

  @Action
  public async add_promocode_line(item: CartItemToUpdate) {
    this.context.commit('fetching');
    try {
      const r = await api.post(`cart/add_promocode_line`, item);
      this.context.commit('fetched', r.data);
    } catch (e) {
      this.context.commit('fetched', this.cart);
    }
  }

  @Action
  public async set_used_promocode() {
    const r = await api.post(`cart/set_used_promocode`, {});
    console.log(r);
  }

  @Action
  public async update(item: CartItemToUpdate) {
    this.context.commit('fetching');
    try {
      const r = await api.post(`cart/${ item.productId }`, {
        quantity: item.quantity,
      });
      this.context.commit('fetched', r.data);
    } catch (e) {
      // TODO: Store error for display!
      this.context.commit('fetched', this.cart);
    }
  }

  @Action
  public async method_payment(item: CartPaymentMedthod) {
    this.context.commit('fetching');
    try {
      const r = await api.post(`cart/method_payment`, {
        paymentMethod: item.paymentMethod,
      });
      this.context.commit('fetched', r.data);
    } catch (e) {
      // TODO: Store error for display!
      this.context.commit('fetched', this.cart);
    }
  }

  @Action
  public async updateRetailerSection(retailerSection: CartRetailerSectionToUpdate) {
    this.context.commit('fetching');
    try {
      const r = await api.post(`cart/retailer/${ retailerSection.retailerId }`, {
        paymentMode: retailerSection.paymentMode,
        deliveryMode: retailerSection.deliveryMode,
      });
      this.context.commit('fetched', r.data);
    } catch (e) {
      // TODO: Store error for display!
      this.context.commit('fetched', this.cart);
    }
  }

  @Action
  public async setPromotialCode(code: string) {
    if(code) {
      this.context.commit('fetching');

      try {
        const r = await api.post(`cart/promotionalcode`, {
          code: code,
        });

        if(r.data) {
          this.cart.promocode = {
            code: r.data.code,
            retCode: r.data.retCode,
            message: r.data.message,
            reduction: r.data.reduction,
            typeReduction: r.data.typeReduction
          }
          this.context.commit('fetched', this.cart);
          return r.data;
        }
      } catch (e) {
        console.log(e)
      }

      this.context.commit('fetched', this.cart);
    }
  }

  @Action
  public async getPromotialCodeProductId() {
    const r = await api.post(`cart/promotionalcode_productid`, {});
    console.log("ProductId: " + r.data);
    return r.data;
  }

  @Action
  public async check() {
    const r = await api.post('cart/check/', {});
    console.log(r);
    this.context.commit('fetched', r.data);
    return r;
  }
}
