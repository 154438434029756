import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Category, CategoryFilter } from '@/models/category';
import { Attribute, AttributeFilter } from '@/models/attribute';
import { AttributeValueFilter } from '@/models/attribute-value';
import { difference, intersection } from '@/utils/set';


@Module({ namespaced: true, name: 'filter' })
export default class FilterModule extends VuexModule {

  public categories: CategoryFilter[] = [];
  public attributes: AttributeFilter[] = [];
  public minPrice: number = 0;
  public maxPrice: number = 0;
  public minPriceFilter: number = 0;
  public maxPriceFilter: number = 0;
  public appliedMinPriceFilter: number = 0;
  public appliedMaxPriceFilter: number = 0;
  public sortValue: string = '';
  public search: string = '';

  private appliedCategoryIds: Set<number> = new Set<number>();
  private appliedValueIds: Set<number> = new Set<number>();


  public get appliedCategories() {
    const appliedCategories: CategoryFilter[] = [];
    const buildSc = (cts: CategoryFilter[]) => {
      for (const c of cts) {
        if (c.applied) {
          appliedCategories.push(c);
        } else {
          buildSc(c.children);
        }
      }
    };
    buildSc(this.categories);
    return appliedCategories;
  }

  public get applyCategories() {
    const applyCategories: CategoryFilter[] = [];
    const buildSc = (cts: CategoryFilter[]) => {
      for (const c of cts) {
        if (c.children.length === 0 && c.apply) {
          applyCategories.push(c);
        }
        buildSc(c.children);
      }
    };
    buildSc(this.categories);
    return applyCategories;
  }

  public get appliedAttributes() {
    const appliedAttributes: Array<{attribute: AttributeFilter, values: AttributeValueFilter[]}> = [];
    for (const attribute of this.attributes) {
      const values: AttributeValueFilter[] = [];
      for (const value of attribute.values) {
        if (value.applied) {
          values.push(value);
        }
      }
      if (values.length) {
        appliedAttributes.push({attribute, values});
      }
    }
    return appliedAttributes;
  }

  public get applyAttributes() {
    const applyAttributes: Array<{attribute: AttributeFilter, value: AttributeValueFilter}> = [];
    for (const attribute of this.attributes) {
      for (const value of attribute.values) {
        if (value.apply) {
          applyAttributes.push({attribute, value});
        }
      }
    }
    return applyAttributes;
  }

  public get hasAppliedFilters() {
    return this.appliedCategories.length || this.appliedAttributes.length ||
      this.minPrice !== this.appliedMinPriceFilter || this.maxPrice !== this.appliedMaxPriceFilter;
  }

  public get hasFiltersToApply() {
    const applyCategoriesIds = new Set(this.applyCategories.map((c) => c.id));
    const applyValuesIds = new Set(this.applyAttributes.map((c) => c.value.id));
    const ics = intersection(this.appliedCategoryIds, applyCategoriesIds).size;
    const ivs = intersection(this.appliedValueIds, applyValuesIds).size;
    return !(ics === this.appliedCategoryIds.size && ics === applyCategoriesIds.size) ||
      !(ivs === this.appliedValueIds.size && ivs === applyValuesIds.size) ||
      this.minPriceFilter !== this.appliedMinPriceFilter || this.maxPriceFilter !== this.appliedMaxPriceFilter;
  }

  @Mutation
  public filtersFetched(filters: any) {
    this.search = filters.search;
    this.categories = filters.categories.map((c: Category) => new CategoryFilter(c, filters.appliedCategoryIds));
    this.appliedCategoryIds = new Set(filters.appliedCategoryIds);
    this.attributes = filters.attributes.map((a: Attribute) => new AttributeFilter(a, filters.appliedValueIds));
    this.appliedValueIds = new Set(filters.appliedValueIds);
    this.minPrice = filters.prices.minPrice;
    this.maxPrice = filters.prices.maxPrice;
    this.appliedMinPriceFilter = filters.prices.minPriceFilter;
    this.appliedMaxPriceFilter = filters.prices.maxPriceFilter;
    this.minPriceFilter = filters.prices.minPriceFilter;
    this.maxPriceFilter = filters.prices.maxPriceFilter;
  }

  @Mutation
  public categoryChecked({category, selected}: { category: CategoryFilter, selected: boolean }) {
    category.apply = selected;
  }

  @Mutation
  public valueChecked({value, selected}: { value: AttributeValueFilter, selected: boolean }) {
    value.apply = selected;
  }

  @Mutation
  public pricesFilterChanged({minPriceFilter, maxPriceFilter}: {minPriceFilter: number, maxPriceFilter: number}) {
    this.minPriceFilter = minPriceFilter;
    this.maxPriceFilter = maxPriceFilter;
  }

  @Mutation
  public sortValueChanged(v: string) {
    this.sortValue = v;
  }

  @Mutation
  public filtersCleared() {
    for (const c of this.categories) {
      c.apply = false;
    }
    for (const a of this.attributes) {
      for (const av of a.values) {
        av.apply = false;
      }
    }
    this.minPriceFilter = this.minPrice;
    this.maxPriceFilter = this.maxPrice;
  }

  @Action
  public checkCategory(data: {category: CategoryFilter, selected: boolean}) {
     this.context.commit('categoryChecked', data);
  }

  @Action
  public checkAttributeValue(data: {value: AttributeValueFilter, selected: boolean}) {
    this.context.commit('valueChecked', data);
  }

  @Action
  public changePricesFilter(v: {minPriceFilter: number, maxPriceFilter: number}) {
    this.context.commit('pricesFilterChanged', v);
  }

  @Action
  public clearFilters() {
    this.context.commit('filtersCleared');
  }

  @Action
  public changeSortValue(v: string) {
     this.context.commit('sortValueChanged', v);
     this.applyFilters();
  }

  @Action
  public applyFilters() {
    const sp = new URLSearchParams();

    for (const c of this.applyCategories) {
      sp.append('fcategory', c.id.toString());
    }

    for (const a of this.applyAttributes) {
      sp.append('fattribute', `${a.attribute.id}_${a.value.id}`);
    }
    if (this.minPriceFilter !== this.minPrice) {
      sp.append('fminprice', this.minPriceFilter.toString());
    }
    if (this.maxPriceFilter !== this.maxPrice) {
      sp.append('fmaxprice', this.maxPriceFilter.toString());
    }

    if (this.sortValue) {
      sp.append('sort', this.sortValue);
    }

    if (this.search) {
      sp.append('search', this.search);
    }

    const filterQuery = sp.toString();
    window.location.search = filterQuery.length > 0 ? '?' + filterQuery : '';
  }

}
