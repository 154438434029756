import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import Wishlist from '@/models/wishlist';
import { WishlistItemToggle } from '@/models/wishlist-item';
import api from '@/api';

@Module({ namespaced: true, name: 'wishlist' })
export default class WishlistModule extends VuexModule {
  public isFetching: boolean = false;
  public isShown: boolean = false;
  public wishlist: Wishlist = {
    items: [],
  };

  @Mutation
  public shown() {
    this.isShown = true;
  }

  @Mutation
  public hidden() {
    this.isShown = false;
  }

  @Action
  public show() {
    this.context.commit('shown');
  }

  @Action
  public hide() {
    this.context.commit('hidden');
  }

  @Mutation
  public fetching() {
    this.isFetching = true;
  }

  @Mutation
  public fetched(wishlist: Wishlist) {
    this.wishlist = {
      items: wishlist.items || [],
    };
    this.isFetching = false;
  }

  @Action
  public async toggle(item: WishlistItemToggle) {
    this.context.commit('fetching');
    try {
      const r = await api.post(`wishlist/${item.productId}`, item);
      this.context.commit('fetched', r.data);
    } catch (e) {
      // TODO: Store error for display!
      this.context.commit('fetched', this.wishlist);
    }
  }

}
